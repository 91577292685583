.credentials {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .form-container {
    position: relative;
    margin: 3rem auto;
    text-align: center;
    background-color: rgb(25, 39, 52);
    width: 380px;
    min-height: 450px;
    border: 1px solid rgba(255,255,255,.5);
    padding-bottom: 60px;
  }

  h1 {
    color: $main-blue;
    font-size: 50px;
    margin-top: 2rem;
  }

  form {
    margin: auto;
    width: 80%;
  }

  .alert {
    font-size: 13px;
    margin: 1.5rem auto;
  }

  .form-group {
    margin: 2rem auto;
  }

  input {
    background-color: rgb(30, 45, 58);
    color: white;
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
  }

  input:focus {
    @extend input;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $main-blue;
  }

  .btn {
    width: 100%;
    margin: 1rem auto 2rem;
    border-radius: 20px;
  }

  .spinner-border {
    width: 20px;
    height: 20px;
  }

  p {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }

  a {
    color: dodgerblue;
    text-decoration: none;
  }
}
