#home {
  position: relative;
  min-height: 100vh;
  border-right: 1px solid rgba(255,255,255,.25);
  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    margin-top: 30px;
  }

  #new-tweet {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 10px solid rgb(37, 51, 65);
    padding-bottom: 0;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    form {
      width: 90%;
  
      .form-group {
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
  
        textarea {
          font-size: 18px;
          background-color: $back-blue;
          color: white;
          border-radius: 0;
          border: none;
          box-shadow: none;
          min-height: 70px;
          resize: none;
          overflow: hidden;
          border-bottom: 1px solid rgba(255,255,255,.25);
        }
      
        button {
          margin: 8px;
          font-weight: bold;
          width: 100px;
          align-self: flex-end;
          border-radius: 20px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  #home {
    border-right: none;

    #new-tweet {
      display: none;
    }
  }
}

@media (max-height: 560px) {
  #home {
    min-width: 65%;
    border-left: 1px solid rgba(255,255,255,.25);
  }
}

@media (max-width: 575px) {
  #home {
    border-left: none;
  }
}
