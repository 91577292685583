#profile {
  position: relative;
  min-height: 100vh;
  border-right: 1px solid rgba(255,255,255,.25);
  display: flex;
  flex-direction: column;
  text-align: center;

  #img-container {
    position: relative;
    padding: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);

    .follow {
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-size: 14px;
      font-weight: bold;
      background-color: $back-blue;
      border-radius: 30px;
      box-shadow: none;
      color: $main-blue;
      border: 1px solid $main-blue;
    }
    
    .follow:focus {
      outline: none;
    }
    
    .followed {
      @extend .follow;
      background-color: $main-blue;
      color: white;
    }

    @media (hover: hover) {
      .follow:hover {
        background-color: $hover-blue;
      }
    
      .followed:hover {
        background-color: $main-blue;
        border: 1px solid $heart;
        transition: border 0s;
      }
      
      .unfollow:hover {
        background-color: $heart;
        border: 1px solid $heart;
        color: white;
      }
    
      .followed:hover::after {
        content: 'Unfollow';
        display: inline-block;
        background-color: $heart;
        font-size: 14px;
        color: white;
        padding: 8px 16px;
        border-radius: 30px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    span {
      position: absolute;
      left: 18px;
      bottom: 12px;
      color: darkgray;
    }

    form {
      position: absolute;
      right: 15px;
      bottom: 0;

      .form-group {
        margin-bottom: 4px;
      }

      label {
        cursor: pointer;
      }

      input {
        display: none;
      }
    }

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 3px solid gray;
    }

    p {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .navbar {
    font-weight: bold;
    padding: 0;
    border-bottom: 1px solid rgba(255,255,255,.25);
    height: 60px;
  }

  .nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: darkgray;
    border-bottom: 3px solid $back-blue;
  }

  .nav-link:hover, .nav-link:active {
    background-color:$hover-blue;
    color: $main-blue;
    border-bottom: 3px solid $main-blue;
  }

  .selected {
    background-color:$hover-blue;
    color: $main-blue;
    border-bottom: 3px solid $main-blue;
  }

  section {
    position: relative;
    height: 100%;
  }

  h3 {
    margin-top: 30px;
  }
}

.follower-container {
  display: flex;
  position: relative;
  padding: 14px 18px;
  justify-content: space-between;
  height: 90px;
  border-bottom: 1px solid rgba(255, 255, 255, .25);

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 6px;
  }

  .follow {
    position: relative;
    z-index: 2;
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    background-color: $back-blue;
    border-radius: 30px;
    box-shadow: none;
    color: $main-blue;
    border: 1px solid $main-blue;
  }
  
  .follow:focus {
    outline: none;
  }
  
  .followed {
    @extend .follow;
    background-color: $main-blue;
    color: white;
  }

  @media (hover: hover) {
    .follow:hover {
      background-color: $hover-blue;
    }

    .followed:hover {
      background-color: $main-blue;
      border: 1px solid $heart;
      transition: border 0s;
    }
  
    .followed:hover::after {
      content: 'Unfollow';
      display: inline-block;
      background-color: $heart;
      font-size: 14px;
      color: white;
      padding: 8px 16px;
      border-radius: 30px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none; /* No underlines on the link */
    z-index: 1; /* Places the link above everything else in the div */
    background-color: #FFF; /* Fix to make div clickable in IE */
    opacity: 0; /* Fix to make div clickable in IE */
    filter: alpha(opacity=1); /* Fix to make div clickable in IE */
  }
}

@media (max-width: 575.98px) {
  #profile {
    border-right: none;
    
    .nav-link {
      font-size: 12px;
      padding: 10px;
    }
  }
}

@media (max-height: 560px) {
  #profile {
    min-width: 65%;
    border-left: 1px solid rgba(255,255,255,.25);
  }
}

@media (max-width: 575px) {
  #profile {
    border-left: none;
  }
}
