#main-navbar {
  position: sticky;
  top: 0;
  height: 100vh;
  font-weight: bold;
  border-right: 1px solid rgba(255,255,255,.25);
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 8px;
  background-color: $back-blue;
  overflow: hidden;

  .selected {
    color: $main-blue !important;
  }

  .navbar-brand {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto 20px;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    
    i {
      font-size: 30px;
    }
  }

  .nav {
    margin: auto;
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .nav-item {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .nav-link {
    margin: auto;
    font-size: 22px;
    color: white;
    border-radius: 30px;
    transition: color 0s;
    display: flex;
    align-items: center;
  }

  .navbar-brand:active {
    background-color: $hover-blue;
  }

  .nav-link:active {
    background-color: $hover-blue;
    color: $main-blue;
  }

  @media (hover: hover) {
    .navbar-brand:hover {
      background-color: $hover-blue;
    }

    .nav-link:hover {
      background-color: $hover-blue;
      color: $main-blue;
    }
  }

  #tweet-button {
    width: 200px;
    height: 50px;
    font-weight: bold;
    border-radius: 25px;
    font-size: 20px;
    margin: 30px auto;
  }

  .user-info {
    text-align: center;
    margin-top: 70px;
  }

  img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
  }

  #log-out {
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  #main-navbar {
    span {
      display: none;
    }

    .navbar-brand {
      margin: auto;
      justify-content: center;
    }

    .nav {
      width: 100%;
    }

    .nav-item {
      width: 100%;
    }

    .nav-link {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      justify-content: center;
    }

    #tweet-button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    img {
      width: 50px;
      height: 50px;
    }

    #log-out {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 575.98px), (max-height: 560px) {
  #main-navbar {
    position: fixed;
    border-top: 1px solid rgba(255,255,255,.25);
    border-right: none;
    height: 10vh;
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 20 !important;
    padding-top: 0;
    padding-bottom: 0;

    .navbar-brand {
      display: none;
    }

    span {
      display: none;
    }

    .nav {
      width: 100%;
      margin: 0;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    .nav-item {
      width: 20%;
      margin: 0;
    }

    .nav-link {
      font-size: 24px;
    }

    .nav-link:focus {
      background-color: transparent;
    }

    #tweet-button {
      width: 58px;
      height: 58px;
      margin: 0;
      position: fixed;
      right: 20px;
      bottom: 90px;
      box-shadow: rgba(136, 153, 166, 0.2) 0px 0px 10px !important;
    }

    .user-info {
      margin: 0;
      width: 25%;

      div {
        display: none;
      }
    }

    #log-out {
      margin: auto;
      height: 40px;
      width: 40px;
    }
  }
}

@media (max-height: 560px) {
  #main-navbar {
    min-width: 100% !important;
  }
}
