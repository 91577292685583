$main-blue: rgb(29, 161, 242);
$back-blue: rgb(21, 32, 43);
$hover-blue: rgb(21, 48, 71);
$light-hover: rgb(0, 128, 255);
$heart: #E74C3C;
$grid-columns: 24 !default;

@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "node_modules/bootstrap/scss/bootstrap";

@import 'credentials';
@import 'navigation';
@import 'profile';
@import 'home';
@import 'explore';

html {
  overflow-y: scroll;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: $back-blue;
  box-sizing: border-box;
  color: white;
  font-family: "Ubuntu", "Roboto";
}

.row {
  position: relative;
}

.btn {
  box-shadow: none !important;
}

a, button {
  transition: background-color 0.2s, color 0.2s, border 0.2s;
}

.tweet-container, .reply-container {
  position: relative;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, .25);
  transition: background-color 0.2s, color 0.2s;
  padding: 14px;
  padding-bottom: 0;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .tweet-main, .reply-main {
    margin-left: 10px;
    margin-right: 6px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  p {
    margin-top: 6px;
    margin-bottom: 8px;
    text-align: left;
    word-break: break-word;
    font-weight: lighter;
  }

  .name-date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
  }

  span {
    font-size: 18px;
  }

  .date {
    font-size: 15px;
    margin-left: 12px;
    font-weight: normal;
    color: darkgray;
  }

  .tweet-reactions {
    z-index: 2;
    align-self: flex-end;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0s, color 0s;

    .btn {
      box-shadow: none;
      padding: 0;
      background-color: transparent !important;
      border: none;
      color: darkgray;
    }

    #reply-button {
      margin-right: 16px;
    }

    .liked {
      color: $heart !important;
      margin-right: 6px;
    }
    
    .like {
      color: darkgray !important;
      margin-right: 6px;
    }

    @media (hover: hover) {
      .liked:hover {
        color: darkgray;
      }

      .like:hover {
        color: $heart;
      }
    }
  }

  .tweet-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none; /* No underlines on the link */
    z-index: 1; /* Places the link above everything else in the div */
    background-color: #FFF; /* Fix to make div clickable in IE */
    opacity: 0; /* Fix to make div clickable in IE */
    filter: alpha(opacity=1); /* Fix to make div clickable in IE */
  }
}

@media (hover: hover) {
  .tweet-container:hover {
    background-color: rgba(21, 48, 71, 0.4);
  }
}

.reply-container {
  width: 100%;
  align-self: flex-end;
  border-left: 10px solid rgb(37, 51, 65);

  .reply-main {
    margin-bottom: 14px;
  }
}

.back-button-div {
  position: sticky;
  background-color: $back-blue;
  z-index: 3;
  top: 0;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, .25);

  .back-button {
    padding: 7px 12px;
    margin-left: 12px;
    border: none;
    color: $main-blue !important;
    background-color: transparent;
    border-radius: 60%;
  }
  
  .back-button:hover {
    background-color: $hover-blue;
  }

  .back-button:active {
    background-color: $hover-blue !important;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 14px;
  }

  h2 {
    font-size: 18px;
    font-weight: bolder;
    margin: 2px 0;
  }

  span {
    font-size: 13px;
    color: darkgray;
  }
}

.modal {
  background-color: rgba(213, 234, 255, 0.2);

  .modal-content {
    border-radius: 16px;
    background-color: $back-blue;
    margin: auto;
    width: 520;
  }

  .modal-header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(255,255,255,.25);
  }

  .close {
    padding: 0 9px 3px 10px;
    font-weight: lighter;
    text-shadow: none;
    outline: none;
    border-radius: 50%;
    color: rgba(29, 161, 242, 1.00);
    margin-left: 0;
    font-size: 36px;
  }

  .close:hover {
    background-color: $hover-blue;
  }

  .modal-body {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    padding-bottom: 0;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  form {
    width: 90%;

    .form-group {
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      textarea {
        width: 95%;
        font-size: 18px;
        background-color: $back-blue;
        color: white;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(255,255,255,.25);
        box-shadow: none;
        min-height: 180px;
        resize: none;
        overflow: hidden;
        padding: 10px auto;
        margin: auto 14px;
      }
    
      button {
        margin: 10px;
        font-weight: bold;
        width: 100px;
        align-self: flex-end;
        border-radius: 20px;
      }
    }
  }
}

.delete-modal {
  font-weight: normal;
  display: flex;
  align-items: center;
  height: 70vh;

  .modal-content {
    width: 400px;
  }

  .modal-body {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  div {
    width: 100%;
    text-align: center;
  }

  .btn {
    margin: auto 20px;
  }
}

#current-tweet {
  position: relative;
  height: 100vh;
  border-right: 1px solid rgba(255, 255, 255, .25);
  display: flex;
  flex-direction: column;

  .tweet-container {
    flex-wrap: wrap;
    border-bottom: 10px solid rgb(37, 51, 65);
  }

  .tweet-container:hover {
    background-color: transparent;
  }

  .tweet-main {
    margin: auto 10px;
    margin-top: 10px;

    p {
      font-size: 26px;
    }
  }

  #reply-button {
    color: darkgray;
  }

  #reply-button:hover {
    color: $main-blue;
  }

  .tweet-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    color: darkgray;
    border-top: 1px solid rgba(255, 255, 255, .25);

    span {
      font-size: 16px;
    }
  }
}

aside {
  position: sticky !important;
  top: 0;
  height: 100vh;
  overflow: hidden;

  #top-followed {
    margin-top: 20px;
    background-color: rgb(30, 45, 58);
    border-radius: 14px;
  }

  h3 {
    padding: 12px 14px;
    font-size: 19px;
    font-weight: bold;
    margin: auto;
  }

  .follower-container {
    border-bottom: none;
    border-top: 1px solid rgba(255, 255, 255, .25);
  }
}

#delete-tweet {
  position: absolute;
  top: 14px;
  right: 14px;
}

.loading {
  position: absolute; 
  left: 0; 
  right: 0;
  top: 50%;
  bottom: 50%;
  margin: auto;
}

.flash-tweet {
  font-size: 14px;
  z-index: 5;
  position: fixed;
  left: 0; 
  right: 0;
  bottom: 10%;
  margin: auto;
  width: 160px;
  text-align: center;

  .alert {
    padding: 8px;
    margin: 0;
    background-color: $light-hover;
    color: white;
    border: none;
  }
}

#notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

@media (max-width: 991.98px) {
  aside {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .row {
    padding-bottom: 10vh !important;
  }

  #current-tweet {
    border-right: none;
  }

  .flash-tweet {
    bottom: 15%;
  }
}

@media (max-height: 560px) {
  #current-tweet {
    min-width: 65%;
    border-left: 1px solid rgba(255,255,255,.25);
  }
}

@media (max-width: 575px) {
  #current-tweet {
    border-left: none;
  }
}
